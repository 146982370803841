import React from 'react'

export const NotFound = props => {

	return (
		<div style={{ textAlign: 'center', 
									marginTop: '3rem'}}className='page-container'>
			<h1>404 not found</h1>
		</div>
	)
}