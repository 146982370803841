export const DefaultViewConfig = {
	order: 'desc', 
	page: 1,
	orderBy: null,
	pageSize: 
		{id: 0,
		 value: 10
		},
	pageSizeOptions: [
		{id: 0,
		 value: 10
		},
		{id: 1,
		 value: 25
		},
		{id: 2,
		 value: 50
		},
		{id: 3,
		 value: 100
		}
	],
	orderByOptions: null
}